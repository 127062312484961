.trading-list-wrap {
  margin-top: 20px;
  background: #FFFFFF;
  border: 0.5px solid #E8E8ED;
  box-shadow: 0px 4px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  padding: 29px 27px 50px;
}
.trading-list-wrap .title {
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 0.12px;
  color: #425466;
}

.trading-list-item, .address-selector {
  display: flex;
  align-items: flex-start;
}
.address-selector {
  cursor: pointer;
  position: relative;
}
.address-selector .address-list {
  position: absolute;
  background-color: #FFFFFF;
  top: 20px;
  border: 0.5px solid #E8E8ED;
  border-radius: 5px;
  box-shadow: 0px 4px 3px 2px rgba(0, 0, 0, 0.02);
  max-height: 300px;
  overflow-y: auto; 
}
.address-selector .address-list a {
  text-decoration: none;
}
.address-selector .address-list p {
  padding: 6px 10px;
  font-size: 14px;
  line-height: 14px;
  color: rgba(0, 128, 255, 1);
}
.address-selector .address-list p:hover {
  background-color: #E8E8ED;
}
.originator.showlist img {
  transform: rotate(-180deg);
}

.trading-list-wrap p {
  margin-bottom: 0;
}

.trading-list-item .content {
  margin-right: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.12px;
  color: #0A0A0A;
}
.trading-list-item .content span {
  color: rgba(66, 84, 102, 0.5);
}
.trading-list-item.originator img {
  margin-top: 5px;
  transition: transform .5s ease;
}

.trading-list-item .name {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  width: 60px;
  letter-spacing: 0.12px;
  color: #425466;
  margin-right: 30px;
}

.trading-list-item.originator {
  margin-top: 33px;
}

.trading-list-item.handle {
  margin-top: 25px;
}

.trading-list-item.total-cost {
  margin-top: 19px;
}

.trading-list-item.status {
  margin-top: 24px;
}

.trading-list-item.handle .handle-wrap {
  width: 1073px;
  /* height: 119px; */
  background: #F9F9FA;
  border-radius: 4px;

  padding: 18px;
}
.trading-list-item.handle .handle-wrap + .handle-wrap {
  margin-top: 20px;
}

.trading-list-item .content.active {
  color: rgba(0, 128, 255, 1);
  font-weight: 500;
}


.trading-list-item.handle .handle-title {
  line-height: 20px;
}

.trading-list-item.handle .handle-title img {
  width: 20px;
  height: 20px;
}
.trading-list-item.handle .handle-title p {
  display: inline-block;
  line-height: 20px;
  margin-left: 11px;
  /* margin-bottom: 0; */
}

.trading-list-item.handle .io p {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.12px;
  color: #425466;
  margin-bottom: 6px
}
.trading-list-item.handle .io p.active {
  color: rgba(0, 128, 255, 1);
}
.trading-list-item.handle .io a {
  text-decoration: none;
}
.trading-list-item.handle .io p span {
  color: rgba(66, 84, 102, 1);
}
.trading-list-item.handle .input {
  width: 439px;
  margin-left: 30px;
}
.trading-list-item.handle .output {
  margin-left: 55px;
}
.trading-list-item.handle .io-container {
  display: flex;
  margin-top: 22px;
  align-items: flex-start;
}
.trading-list-item.handle .io-container img {
  width: 6px;
  height: auto;
  /* margin-left: 55px; */
  margin-top: 2px;
}