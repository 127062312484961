thead tr {
  background: #FAFAFB;
  min-height: 52px;
  border-top: 1px solid #EBEBF0;
}
th, td {
  /* padding: 0 0 18px 24px; */
  padding-left: 18px;
  box-shadow: inset 0px -1px 0px #EDF2F7;
}
thead td {
  font-weight: 400!important;
  letter-spacing: 0.06em;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #8492A6;
}
td {
  font-size: 14px;
  line-height: 14px;
  color: #425466;
  font-weight: 300;
  /* height: 52px; */
  height: 52px;

  /* min-height: 52px; */
  /* display: inline-block; */
}

td a {
  color: #425466;
  text-decoration: none;
  display: block;
  width: 100%;
  overflow: hidden;
  overflow-wrap: anywhere;
}

td p {
  margin: 0;
}
td.active, td .active {
  cursor: pointer;
}
tbody p.active {
  color: #0080FF;
}
tbody p.hover:hover {
  color: #0080FF;
  cursor: pointer;
}
thead td img {
  margin-left: 5px;
}

.table-define-wrap {
  position: relative;
}
.table-define-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  /* padding-left: 24px; */
  padding-left: 18px;
}
.mutiple-body {
  vertical-align: top;
}
.mutiple-body td {
  padding-top: 19px;
  padding-bottom: 19px;
}
.table-define-mutiple {
  height: auto;
}
.table-define-mutiple .table-define-div {
  position: static;
  padding: 0;
}
/* .table-define-mutiple .table-define-div + .table-define-div {
  margin-top: 5px;
} */

.table-define-mutiple p, .table-define-mutiple .table-define-div {
  /* padding-top: 5px; */
  height: 25px;
  line-height: 25px;
}

.table-errortext {
  padding: 10px;
  font-size: 14px;
}