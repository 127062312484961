.search-container {
  margin: auto;
  width: 800px;
}
.inputwrap {
  height: 50px;
  display: flex;
  position: relative;
  z-index: 100;
}
.input {
  width: 700px;
  height: 50px;
  padding: 17px;
  border-radius: 4px 0 0 4px;
  outline: none;
  border: none;
}
.input::placeholder {
  color: #999999;
}
.button {
  width: 100px;
  height: 50px;
  border: none;
  background: #FF6833;
  font-size: 20px;
  color: #ffffff;
  border-radius: 0 4px 4px 0;
}

.select-container {
  background: #ffffff;
  margin-top: -5px;
  padding-top: 5px;
  padding-bottom: 14px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 4px 4px;
  position: absolute;
  width: 800px;
}
.result-text {
  padding: 18px 18px 0px;
  font-size: 12px;
  line-height: 16px;
  color: #999999;
  border-top: 1px solid rgba(217, 217, 217, 0.8);
  margin-bottom: 10px;
}
.result-text span {
  color: #FF6833;
}
.select-item div {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  display: inline-block;
}
.select-item img {
  width: 30px;
  height: 30px;
  margin-right: 13px;
}
.select-item p {
  display: inline-block;
  margin-bottom: 0;
  /* background-color: #000; */
}

.select-item div:hover {
  color: #4C6FFF;
  cursor: pointer;
}

.loading-icon {
  /* margin: 10px; */
  border-top: 1px solid rgba(217, 217, 217, 0.8);
  text-align: center;
  padding-top: 15px;
}

