.pie-container {
  width: 290px;
  height: 601px;
  background: #FFFFFF;
  border: 0.5px solid #E8E8ED;
  box-shadow: 0px 4px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
}
.pie-container div.chart {
  position: relative;
}
.pie-container div.chart img {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -24px;
  margin-left: -24px;
  width: 48px;
  height: 48px;
  transition: transform .5s ease;
}
.pie-container div.chart img:hover {
  transform: scale(1.1);
}


/* data-list */
.data-list {
  padding: 0 40px;
  height: 320px;
  overflow: auto;
}
.data-list .data-color {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-top: 6px;
}
.data-list p {
  margin: 0;
}
.data-list .data-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-left: 6px;
}
.data-list .data-score {
  font-size: 14px;
  line-height: 20px;
  color: rgba(66, 84, 102, 0.5);
}
.data-item, .data-item div {
  display: flex;
  align-items: flex-start;
}
.data-item div p {
  word-break: break-all;
  width: 150px;
}
.data-item {
  justify-content: space-between;
}
.data-item + .data-item {
  margin-top: 20px;
}