.alert-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
}

.alert-win {
  width: 532px;
  margin: auto;
  background: #ffffff;
  border-radius: 4px;
}
.alert-win .header {
  border-bottom: 1px solid #EBEBF0;;
}
.alert-win .header .title {
  height: 57px;
  line-height: 57px;
  padding-left: 24px;
  display: inline-block;
  width: 490px;
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  color: #0A0A0A;
}
.alert-win .header img {
  width: 20px;
  cursor: pointer;
}
.alert-win .subtitle {
  padding: 24px;
  font-size: 14px;
  margin: 0;
}

.alert-win .button-wrap {
  padding-bottom: 24px;
  text-align: right;
  padding-right: 24px;
}
.alert-win .button-wrap .success-button {
  margin-left: 16px;
}

.alert-win .alert-forms {
  padding-left: 24px;
  padding-right: 24px;
}