.container {
  /* padding-left: 24px;
  padding-right: 24px; */
  margin-bottom: 16px;
  position: relative;
}
.title {
  font-size: 12px;
  line-height: 16px;
  color: #646F79;
  margin-bottom: 6px;
}
.select {
  padding: 14px 16px;
  border: 1px solid #DBDDE0;
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  background: #ffffff;
}
.selectIcon {
  margin-left: 10px;
}
.content {
  width: 424px;
  display: inline-block;
}
.content img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-top: -2px;
}
.option {
  background: #fff;
  border: 1px solid #DBDDE0;
  margin-top: 0px;
  /* padding: 6px 16px; */
  border-radius: 0 0 6px 6px;
  font-size: 14px;
  position: absolute;
  top: 66px;
  /* left: 24px; */
  width: 484px;
  z-index: 1;
  max-height: 300px;
  overflow: auto;
}
.option .content {
  padding: 10px 16px;
  cursor: pointer;
  width: 100%;
}
.option .content:hover {
  background: #f9f6f6;
}