.MyListPage-title {
  color: #425466;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.12px;
  display: flex;
  padding: 10px 16px 10px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  width: 396px;
  margin-bottom: 10px;
}

.MyListPage-desc {
  color: #425466;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  /* 100% */
  letter-spacing: 0.12px;
  margin-bottom: 32px;
  width: auto;
  text-align: left;
}

.MyListPage-page-container {
  border-radius: 4px;
  border: 0.5px solid #E8E8ED;
  background: #FFF;
  box-shadow: 0px 4px 3px 2px rgba(0, 0, 0, 0.02);
}

