.container {
  /* padding-left: 24px;
  padding-right: 24px; */
  margin-bottom: 16px;
  position: relative;
}
.title {
  font-size: 12px;
  line-height: 16px;
  color: #646F79;
  margin-bottom: 6px;
}
.container input {
  width: 484px;
  height: 40px;
  border: 1px solid #DBDDE0;
  border-radius: 6px;
  font-size: 14px;
  padding: 0 10px;
}