
.addresswintitle img {
  width: 20px;
}
.addresswintitle span {
  font-size: 16px;
  font-weight: normal;
  padding-left: 10px;
}
.adddresstopic {
  font-size: 14px;
  margin-bottom: 15px;
}
.addresslimit {
  margin-top: 20px;
}
.addresstable {
  height: 573px;
}